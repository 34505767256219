/*
--01 Typography design

-Font sizes (px)

-Font weights
Default: 400

-Line heights
Default: 1


-space systems



--02 Colors

- Primary: #28df99

- Tints: #99f3b7
- Shade: #d2f6c5
- Accents: #f6f7d4
- Grey: #555


--05 Shadow


--06 Border radius


--07 White space



*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  /* font-size: 10px */
  font-size: 62.5%;
  font-family: "Sarabun", sans-serif;
}

body {
  line-height: 1;
  font-weight: 400;
}

h1 {
  font-size: 3rem;
  text-align: center;
}

h3 {
  font-size: 2rem;
  text-align: center;
  padding: 1.6rem 0;
}
h4 {
  font-size: 1.8rem;
  text-align: center;
}

.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 2.4rem;
}

.grid {
  display: grid;
  gap: 2.4rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--6-cols {
  grid-template-columns: repeat(6, 1fr);
}

.grid--7-cols {
  grid-template-columns: repeat(7, 1fr);
}

.grid--8-cols {
  grid-template-columns: repeat(8, 1fr);
}

/************************************/
/* Header */

.header {
  background-color: #69e9b8;
  padding: 1rem 0;

  /* Sticky header */
  height: 5.2rem;
  display: block;
  width: 100%;
}

.header-sticky {
  position: fixed;
  top: 0;
  bottom: 0;
  background-color: #69e9b8;
  width: 100%;
  z-index: 100;
}

.header-container {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.header-company {
  display: flex;
  align-items: flex-end;
  margin-right: auto;
}

.header-company span {
  font-size: 1.6rem;
  font-weight: 600;
  color: #333;
  padding: 1.2rem 0 0.4rem 1.2rem;
}

.logo {
  max-height: 4rem;
  padding: 0 0.5rem;
}

/********************************************/
/*Login and Cart */

.header-menu {
  margin-left: auto;
}

/********************************************/
/* Main Navigation */

.main-nav-list,
.login-cart-list {
  list-style: none;
  display: flex;
  align-items: flex-end;
}

.main-nav-link:link,
.main-nav-link:visited {
  display: inline-block;
  text-decoration: none;
  color: #333;
  font-weight: 600;
  font-size: 1.6rem;
  padding: 1.2rem;
  transition: all 0.3s;
}

@media (max-width: 600px) {
  .header-company span {
    display: none;
  }

  .main-nav-link:link,
  .main-nav-link:visited,
  .main-nav-link:hover,
  .main-nav-link:active {
    font-size: 1.4rem;
    padding: 0.8rem;
  }
  .header {
    height: 5.2rem;
  }
  .container {
    padding: 0 1.2rem;
  }
  .header-container {
    gap: 0.8rem;
  }
}

@media (max-width: 465px) {
  .container {
    padding: 0 0.6rem;
  }
  .header-container {
    gap: 0.4rem;
  }
}

.main-nav-link:hover,
.main-nav-link:active {
  color: #18865c;
  transform: scale(105%);
}

.main-nav-link.nav-cta:link,
.main-nav-link.nav-cta:visited {
  padding: 1.2rem 2.4rem;
  background-color: #18865c;
  color: #fff;
  border-radius: 9px;
}

.main-nav-link.nav-cta:hover,
.main-nav.link.nav-cta:active {
  background-color: #18865c;
  color: #fff;
  transform: scale(105%);
}

@media (max-width: 600px) {
  .main-nav-link.nav-cta:link,
  .main-nav-link.nav-cta:visited {
    padding: 1.2rem 1.2rem;
  }
}

@media (max-width: 350px) {
  .logo {
    max-height: 3.6rem;
    padding: 0 0.3rem;
  }
  .main-nav-link:link,
  .main-nav-link:visited,
  .main-nav-link:hover,
  .main-nav-link:active {
    font-size: 1.2rem;
    padding: 0.6rem;
  }
  .main-nav-link.nav-cta:link,
  .main-nav-link.nav-cta:visited {
    padding: 0.6rem 0.6rem;
  }
}

/**************************************************/
/* Section-search */

.section-search {
  background-color: #69e9b8;
  height: 4rem;
}

.search-sticky {
  position: fixed;
  top: 5.2rem;
  bottom: 0;
  background-color: #69e9b8;
  width: 100%;
  z-index: 100;
}

.search-container {
  width: 100%;
  display: flex;
  padding-bottom: 1rem;
  align-items: center;
}

.searchbar {
  margin-left: auto;
  font-size: 1.6rem;
}

.searchbar input {
  border-radius: 100px;
  padding: 0.5rem;
  background-color: #eee;
  border: none;
  font-family: inherit;
  font-size: inherit;
  min-width: 40rem;
  height: 2.4rem;
}

.search-btn:hover,
.search-btn:active {
  transform: scale(150%);
  cursor: pointer;
}

@media (max-width: 800px) {
  .searchbar input {
    min-width: 30rem;
  }
}

@media (max-width: 600px) {
  .search-container {
    flex-direction: column;
    align-items: stretch;
  }
  .section-search {
    height: 6rem;
  }

  .searchbar {
    font-size: 1.4rem;
    margin-left: 0;
  }
}

.search-btn {
  border: none;

  border-radius: 100px;
  margin-left: -2.4rem;
  height: 2.4rem;
}

@media (max-width: 350px) {
  .searchbar {
    font-size: 1rem;
  }
  .search-btn {
    height: 2rem;
  }
  .searchbar input {
    min-width: 25rem;
  }
}

@media (max-width: 250px) {
  .searchbar input {
    min-width: 20rem;
  }
}

/**************************************************/
/* Main */

.main {
  margin-top: 10.4rem;
}

@media (max-width: 600px) {
  .main {
    margin-top: 12rem;
  }
}

/***************************************************/
/* Section List Product */

.product-grid {
  grid-template-columns: repeat(5, 1fr);
}

.section-listProduct {
  padding: 2.4rem;
}

@media (max-width: 1000px) {
  .product-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media (max-width: 800px) {
  .product-grid {
    grid-template-columns: repeat(3, 1fr);
  }
  .section-listProduct {
    padding: 1.2rem;
  }
}

.product-image {
  width: 100%;
}

.product-attribute {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  align-items: center;
  font-size: 1.4rem;
}

.product-title {
  font-size: 1.8rem;
  padding-top: 1.2rem;
}

.product-nameEng {
  font-size: 1.4rem;
  text-align: center;
}

.product-category {
  font-size: 1.4rem;
}

.product-form {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.product-form select {
  background-color: #eee;
  border: none;
  border-radius: 100px;
  padding: 0.6rem;
  color: inherit;
  font-family: inherit;
}

.btn--add-to-cart {
  border: none;
  border-radius: 100px;
  background-color: #54ba93;
  font-size: 1.6rem;
  font-weight: 500;
  height: 2.4rem;
  color: #fff;
  transition: all 0.3s;
}

.btn--add-to-cart:link,
.btn--add-to-cart:hover {
  background-color: #4aa381;
  transform: scale(105%);
}

.vat-describe {
  font-size: 1rem;
  text-align: center;
}

@media (max-width: 600px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .product-title {
    font-size: 1.6rem;
    padding-top: 1.2rem;
  }
  .product-attribute {
    gap: 0.8rem;
  }
  .product-category {
    font-size: 1.4rem;
  }
  .btn--add-to-cart {
    font-size: 1.4rem;
  }
  .product-form {
    gap: 0.8rem;
  }
  .product-form select {
    font-size: 1.4rem;
  }
}

@media (max-width: 465px) {
  .product-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .product-title {
    font-size: 1.2rem;
    padding-top: 0.6rem;
  }
  .product-attribute {
    gap: 0.6rem;
  }
  .product-category {
    font-size: 1rem;
  }
  .btn--add-to-cart {
    font-size: 1rem;
  }
  .product-form {
    gap: 0.6rem;
  }
  .product-form select {
    font-size: 1rem;
  }
  .vat-describe {
    font-size: 0.8rem;
  }
}

@media (max-width: 300px) {
  .product-title {
    font-size: 1rem;
    padding-top: 0.4rem;
  }
  .product-attribute {
    gap: 0.4rem;
  }
  .product-category {
    font-size: 0.8rem;
  }
  .btn--add-to-cart {
    font-size: 0.8rem;
  }
  .product-form {
    gap: 0.4rem;
  }
  .product-form select {
    font-size: 0.8rem;
  }
  .vat-describe {
    font-size: 0.6rem;
  }
}

/**************************************************
/* Section Price List */

.table-wrapper {
  max-width: 1200px;
  width: 100%;
  margin: 2rem auto;
  font-size: 1.6rem;
  line-height: 2.8rem;
  position: relative;
  transition: all 0.2s ease-out;
}

.card-list-table {
  border-collapse: collapse;
  width: 100%;
}

.table-head {
  text-align: center;
}

.table-column {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

@media (max-width: 800px) {
  .column-INCI {
    display: none;
  }
}

@media (max-width: 450px) {
  .table-wrapper {
    font-size: 1.4rem;
  }
}

@media (max-width: 350px) {
  .table-wrapper {
    font-size: 1.2rem;
  }
}

/****************************************************/
/* Cart Page */

.cartpage-grid {
  display: grid;
  grid-template-columns: 7fr 3fr;
}

/****************************************************/
/* Add Line */

.add-line-section {
  background-color: #69e9b8;
  padding: 3.6rem;
}

.line-QR {
  display: inline-block;
  max-width: 20rem;
  padding: 2.4rem;
}

.add-line {
  display: flex;
  align-items: center;
  font-size: 2.8rem;
  line-height: 1.5;
}

/*************************************************/
/* Footer */

.footer {
  background-color: #24c98a;
  font-size: 1.4rem;
}
.footer-container {
  text-align: center;
  font-size: inherit;
  font-weight: 400;

  padding: 2rem 1rem;
  line-height: 1.5;
}

@media (max-width: 600px) {
  .footer {
    font-size: 1.2rem;
  }
}

@media (max-width: 465px) {
  .footer {
    font-size: 1.2rem;
  }
}

@media (max-width: 350px) {
  .footer {
    font-size: 0.8rem;
  }
}
